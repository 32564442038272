/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'jKbuygZtQSEqt12yzKKAxe.png': { uri: '/images/jKbuygZtQSEqt12yzKKAxe.png' },
'2RfmxNEJqZ3Y8bww71xw4S.png': { uri: '/images/2RfmxNEJqZ3Y8bww71xw4S.png' },
'wjuHcfKzhMPwLS3KGPaPNd.png': { uri: '/images/wjuHcfKzhMPwLS3KGPaPNd.png' },
'2bfukx9J6xt5uL5Ny7tuZj.png': { uri: '/images/2bfukx9J6xt5uL5Ny7tuZj.png' },
'cggL2WNFD4vfectZ55QrUD.png': { uri: '/images/cggL2WNFD4vfectZ55QrUD.png' },
'9bMzbiSNatq5vYT5cq5DZp.png': { uri: '/images/9bMzbiSNatq5vYT5cq5DZp.png' },
'bxCqKL7u2CYECNtvkicbvA.png': { uri: '/images/bxCqKL7u2CYECNtvkicbvA.png' },
'mykhMUR3WjEtiBKrxkpQ8p.png': { uri: '/images/mykhMUR3WjEtiBKrxkpQ8p.png' },
'wtWARsMc6PrH24LRkNBm7C.png': { uri: '/images/wtWARsMc6PrH24LRkNBm7C.png' },
'm5MvVbDYRuxXJXx3NzH855.png': { uri: '/images/m5MvVbDYRuxXJXx3NzH855.png' },
'33369Cj7Fo2Eze7tU9ZF9c.png': { uri: '/images/33369Cj7Fo2Eze7tU9ZF9c.png' },
'ao615j2CwdMqAvRfcLZxPp.png': { uri: '/images/ao615j2CwdMqAvRfcLZxPp.png' },
'75y95LRL9iJaQJ873tJ6rH.png': { uri: '/images/75y95LRL9iJaQJ873tJ6rH.png' },
'odvgEEJpAnP2p1JSWBkCK1.png': { uri: '/images/odvgEEJpAnP2p1JSWBkCK1.png' },
'1vxJiPQJoPvx5qVhQt3iRJ.png': { uri: '/images/1vxJiPQJoPvx5qVhQt3iRJ.png' },
'aUKRY6opjsocEBCXrUgAod.png': { uri: '/images/aUKRY6opjsocEBCXrUgAod.png' },
'1SvEAG8ozNthujQGwVB9hX.png': { uri: '/images/1SvEAG8ozNthujQGwVB9hX.png' },
'4Cb2iy8RFAa5uHn38KCmfJ.png': { uri: '/images/4Cb2iy8RFAa5uHn38KCmfJ.png' },
'e75a3FX1fbEpgGDg1UKv7N.png': { uri: '/images/e75a3FX1fbEpgGDg1UKv7N.png' },
'xeog94dhYMVkgKLDjkjVUH.png': { uri: '/images/xeog94dhYMVkgKLDjkjVUH.png' },
'2tVcg6GtBtekAw11fZkZJE.png': { uri: '/images/2tVcg6GtBtekAw11fZkZJE.png' },
'jqGPpXX9WhscqZVvUo77YR.png': { uri: '/images/jqGPpXX9WhscqZVvUo77YR.png' },
'hyz81ji5TpkMXHD3TvDE4G.png': { uri: '/images/hyz81ji5TpkMXHD3TvDE4G.png' },
'aZzWmtvDcimEqmJCwT1XSQ.png': { uri: '/images/aZzWmtvDcimEqmJCwT1XSQ.png' },
'53HVzsu49NnGbizyX9XpGo.png': { uri: '/images/53HVzsu49NnGbizyX9XpGo.png' },
'qUuVwzFzQGUbk2sAzR7DPy.png': { uri: '/images/qUuVwzFzQGUbk2sAzR7DPy.png' },
'w6wERBNkZDrf1c9oHwGsgg.png': { uri: '/images/w6wERBNkZDrf1c9oHwGsgg.png' },
'1dMUUEAZmZSVLGf4YZ1i8f.png': { uri: '/images/1dMUUEAZmZSVLGf4YZ1i8f.png' },
's6boLvnyXRiLfK5hV2ktD1.png': { uri: '/images/s6boLvnyXRiLfK5hV2ktD1.png' },
'uKhw2oLzZvvi5bc7Yk4fWK.png': { uri: '/images/uKhw2oLzZvvi5bc7Yk4fWK.png' },
'jEeNfpF2BGwhJxHYq25bXi.png': { uri: '/images/jEeNfpF2BGwhJxHYq25bXi.png' },
'fV9EZyGePvSA78cut7dug8.png': { uri: '/images/fV9EZyGePvSA78cut7dug8.png' },
'x3XHJhN7ffcmwKtS7pToZ2.png': { uri: '/images/x3XHJhN7ffcmwKtS7pToZ2.png' },
'34TZFB6V3eRhreUuyriJpV.png': { uri: '/images/34TZFB6V3eRhreUuyriJpV.png' },
'evqbsYn921rQG6uEL3JDtj.png': { uri: '/images/evqbsYn921rQG6uEL3JDtj.png' },
'ecgcfL8x1ra8EJM9FK2ecT.png': { uri: '/images/ecgcfL8x1ra8EJM9FK2ecT.png' },
'drFDhepr9Tq2GmLpaw3zrQ.png': { uri: '/images/drFDhepr9Tq2GmLpaw3zrQ.png' },
'wWoycTBeDiNfDyLEmRdEP5.png': { uri: '/images/wWoycTBeDiNfDyLEmRdEP5.png' },
'nLmzboERyRcDneX6r1MoJT.png': { uri: '/images/nLmzboERyRcDneX6r1MoJT.png' },
'5VvUTsLrfUiRb4EdP4TrF6.png': { uri: '/images/5VvUTsLrfUiRb4EdP4TrF6.png' },
'7pTuBmTeTy8WAtEm2cLpQQ.png': { uri: '/images/7pTuBmTeTy8WAtEm2cLpQQ.png' },
'4AFuVfL5bAbxXHCcBwCsmX.png': { uri: '/images/4AFuVfL5bAbxXHCcBwCsmX.png' },
'dGQmrtMhRiRvYJcCs6TRN3.png': { uri: '/images/dGQmrtMhRiRvYJcCs6TRN3.png' },
'4HCNzLmHKHmzxv4z5YzP1S.png': { uri: '/images/4HCNzLmHKHmzxv4z5YzP1S.png' },
'kwjRcn9Jzzc1nYfFxKmSfA.png': { uri: '/images/kwjRcn9Jzzc1nYfFxKmSfA.png' },
'k4MNiceUnVmUfEnPqn5mWq.png': { uri: '/images/k4MNiceUnVmUfEnPqn5mWq.png' },
'ae9KU2MerNsM7XEHKbEz6m.png': { uri: '/images/ae9KU2MerNsM7XEHKbEz6m.png' },
'137QBcSzLpmB4AyoaeF7SG.png': { uri: '/images/137QBcSzLpmB4AyoaeF7SG.png' },
'khyoQjGsjNRjJQhTso3wbd.png': { uri: '/images/khyoQjGsjNRjJQhTso3wbd.png' },
'sYWQzmEXp3pmLFWe1jXzQJ.png': { uri: '/images/sYWQzmEXp3pmLFWe1jXzQJ.png' },
'ihAQx6jqVzwyftsqPu9ZaY.jpg': { uri: '/images/ihAQx6jqVzwyftsqPu9ZaY.jpg' },
'aQQyd5ZfoVa6NUKihmAwYZ.jpg': { uri: '/images/aQQyd5ZfoVa6NUKihmAwYZ.jpg' },
'bsxy4366etyb5muXzEh1WV.png': { uri: '/images/bsxy4366etyb5muXzEh1WV.png' },
'cJB6BcBFdF9PPGMspoJ82f.png': { uri: '/images/cJB6BcBFdF9PPGMspoJ82f.png' }
}

export default imageStaticSourcesByFileName
